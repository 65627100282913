import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'

const useWindowSize = () => {
  const getSize = useCallback(() => {
    return {
      innerHeight: typeof window !== 'undefined' && window.innerHeight,
      innerWidth: typeof window !== 'undefined' && window.innerWidth,
      outerHeight: typeof window !== 'undefined' && window.outerHeight,
      outerWidth: typeof window !== 'undefined' && window.outerWidth,
    }
  }, [])
  const [windowSize, setWindowSize] = useState(getSize())

  const listener = useCallback(() => {
    setWindowSize(getSize())
  }, [getSize])
  const handler = debounce(listener, 100, true)
  useEffect(() => {
    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
    }
  }, [handler, listener])

  return windowSize
}

export default useWindowSize
