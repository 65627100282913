import './Parallax.scss'

import Bowser from 'bowser'
import throttle from 'lodash/throttle'
import PropTypes from 'prop-types'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react'

import windowSize from '../hooks/windowSize'

const Parallax = ({ children, factor }) => {
  const { innerHeight } = windowSize()
  const parallaxEl = useRef(null)

  const isInvalidBrowser = useMemo(() => {
    if (typeof window !== 'undefined') {
      const browser = Bowser.getParser(window.navigator.userAgent)
      return browser.satisfies({
        'Internet Explorer': '>0',
        'Microsoft Edge': '<45',
      })
    }
  }, [])

  const animateEl = useCallback(() => {
    if (!isInvalidBrowser) {
      const initialPos =
        parallaxEl.current &&
        parallaxEl.current.parentElement.getBoundingClientRect().top
      const elHeight =
        parallaxEl.current &&
        parallaxEl.current.getBoundingClientRect().height
      const calcOffset = (0 - initialPos) * factor * 0.083
      const elTop =
        Math.min(initialPos + calcOffset, initialPos) - innerHeight
      const elBottom =
        Math.max(initialPos + calcOffset, initialPos) + elHeight
      if (parallaxEl.current && elTop < 500 && elBottom > -500) {
        window.requestAnimationFrame(() => {
          parallaxEl.current.style.transform = `translate3d(0, ${calcOffset}rem, 0)`
        })
      }
    }
  }, [parallaxEl, isInvalidBrowser, factor, innerHeight])

  const handleAnimateEl = throttle(animateEl, 20)
  useLayoutEffect(animateEl, [animateEl])

  useLayoutEffect(() => {
    window.addEventListener('scroll', handleAnimateEl, {
      passive: true,
    })
    return () => {
      window.removeEventListener('scroll', handleAnimateEl, {
        passive: true,
      })
    }
  }, [handleAnimateEl])

  return (
    <>
      <div className={'parallax'} ref={parallaxEl}>
        <div className="parallax-content">{children}</div>
      </div>
    </>
  )
}

export default Parallax

Parallax.defaultProps = {
  factor: 0.125,
}

Parallax.propTypes = {
  children: PropTypes.node,
  factor: PropTypes.number,
}
