import './IconBlock.scss'

import PropTypes from 'prop-types'
import React from 'react'

const IconBlock = ({ data }) => {
  const getHtml = node => ({
    __html: node.childMarkdownRemark.html,
  })
  return (
    <div className="list-item">
      <img className="icon" src={data.icon.url} alt="" />
      <div className="content">
        <h4>{data.title}</h4>
        <div dangerouslySetInnerHTML={getHtml(data.descriptionNode)} />
      </div>
    </div>
  )
}

export default IconBlock

IconBlock.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    descriptionNode: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }),
    }),
    icon: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  }),
}
