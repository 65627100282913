import '../styles/capabilities.scss'

import { Link, graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import IconBlock from '../components/IconBlock'
import ImageHero from '../components/ImageHero'
import Parallax from '../components/Parallax'
import SEO from '../components/SEO'
import Layout from '../layouts/Layout'
import linkShapes from '../utils/linkShapes'

const CapabilitiesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "capabilities-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      powders: file(relativePath: { eq: "strawberry-shake.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 840, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      capsules: file(relativePath: { eq: "capsules-dissolving.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 840, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      softGels: file(relativePath: { eq: "soft-gels.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 840, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      gelPack: file(relativePath: { eq: "gel-pack.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 840, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cream: file(relativePath: { eq: "cream.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 840, quality: 75) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      datoCmsCapabilitiesPage {
        heroHeadline
        heroBody
        heroBodyNode {
          childMarkdownRemark {
            html
          }
        }
        stats {
          id
          number
          plus
          title
        }
        capabilitiesHeadline
        capabilitiesBodyNode {
          childMarkdownRemark {
            html
          }
        }
        capabilities {
          id
          title
          networkSites {
            id
            siteName
          }
          capabilitiesNode {
            childMarkdownRemark {
              html
            }
          }
          packagingNode {
            childMarkdownRemark {
              html
            }
          }
          image {
            fluid(maxWidth: 840) {
              ...GatsbyDatoCmsFluid
            }
          }
          parallaxDirection
        }
        expertiseHeadline
        expertise {
          id
          title
          descriptionNode {
            childMarkdownRemark {
              html
            }
          }
          icon {
            url
          }
        }
      }
    }
  `)

  const dato = data.datoCmsCapabilitiesPage
  const getHtml = node => ({
    __html: node.childMarkdownRemark.html,
  })

  return (
    <Layout id="capabilities">
      <SEO title="Our Capabilities" description={dato.heroBody} />
      <ImageHero
        fluid={data.hero.childImageSharp.fluid}
        alt="INW Product: Vanilla Bean Protein Powder"
      >
        <h1>{dato.heroHeadline}</h1>
        <div
          className="lede"
          dangerouslySetInnerHTML={getHtml(dato.heroBodyNode)}
        />
      </ImageHero>

      <section id="stat-bar">
        {dato.stats.map(stat => (
          <div className="stat" key={stat.id}>
            <span className="number">
              {stat.number}
              {stat.plus && <span className="plus" />}
            </span>
            <span className="title">{stat.title}</span>
          </div>
        ))}
      </section>

      <section id="capabilities-section">
        <section className="intro">
          <Parallax factor={0.05} id="background" />
          <h2>{dato.capabilitiesHeadline}</h2>
          <div
            className="lede"
            dangerouslySetInnerHTML={getHtml(dato.capabilitiesBodyNode)}
          />
        </section>
        {dato.capabilities.map(capability => (
          <div
            className="product-type"
            id={capability.title.replace(/[\s/]+/g, '-').toLowerCase()}
            key={capability.id}
          >
            <div className="content">
              <h3>{capability.title}</h3>
              <div className="details">
                <h5>Network Sites</h5>
                <ul>
                  {capability.networkSites.map(site => (
                    <li key={site.id}>
                      <Link to={linkShapes.networkSite(site.siteName)}>
                        {site.siteName}
                      </Link>
                    </li>
                  ))}
                </ul>
                <h5>Capabilities</h5>
                <div
                  dangerouslySetInnerHTML={getHtml(
                    capability.capabilitiesNode
                  )}
                />
                <h5>Packaging</h5>
                <div
                  dangerouslySetInnerHTML={getHtml(
                    capability.packagingNode
                  )}
                />
              </div>
            </div>
            <div className="image">
              <Parallax
                factor={
                  capability.parallaxDirection === 'Down' ? 0.05 : -0.05
                }
              >
                <Img
                  fluid={capability.image.fluid}
                  imgStyle={{ objectFit: 'contain' }}
                />
              </Parallax>
            </div>
          </div>
        ))}
      </section>

      <section id="expertise-section">
        <h2>{dato.expertiseHeadline}</h2>
        <div id="expertise-list">
          {dato.expertise.map(expertise => (
            <IconBlock data={expertise} key={expertise.id} />
          ))}
        </div>
      </section>
    </Layout>
  )
}

export default CapabilitiesPage
